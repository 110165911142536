import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { Share2, Clipboard, Link } from "lucide-react";
import { Popover, PopoverTrigger } from "../ui/popover";
import { PopoverContent } from "@radix-ui/react-popover";
import ShadTooltip from "../ShadTooltipComponent";
import { Input } from "../ui/input";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Label } from "@radix-ui/react-label";

export default function SharePop({
  children,
  id,
  isComponent,
}: {
  children: JSX.Element;
  isComponent: boolean;
  id: string;
}) {
  function getUrl() {
    let url = window.location.href;

    // Ensure the URL ends with a slash
    if (!url.endsWith("/")) {
      url += "/";
    }

    // Remove the last segment
    url = url.substring(0, url.lastIndexOf("/", url.length - 2) + 1);
    const dividedUrl: string[] = url.split("/");

    // Add /store if it is not already included
    const storeUrl = "store/";
    const urlHasStore = dividedUrl[dividedUrl.length - 2 ] === "store";
    if (!urlHasStore) {
      url += storeUrl;
    }

    return url;
  }
  function handleShare(link: boolean) {
    if (link) navigator.clipboard.writeText(getUrl() + id);
    else {
      navigator.clipboard.writeText(id);
    }

    toast({
      title: "Copied to clipboard",
      description: "Share it with your friends.",
    });
  }

  return (
    <Popover>
      <PopoverTrigger className="">{children}</PopoverTrigger>
      <PopoverContent className="z-50 max-w-[22em] shadow-md">
        <Card>
          <CardHeader className="">
            <CardTitle>Share {isComponent ? "Component" : "Flow"} </CardTitle>
            <CardDescription>
              Share ID for Langflow Store search{" "}
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <div className="space-y-1">
              <div className="flex gap-2">
                <Label>Copy Link</Label>
              </div>
              <div className="relative">
                <Input
                  type="text"
                  value={getUrl() + id}
                  readOnly
                  className="truncate w-full pr-[5em]"
                />
                <Button
                  variant={"primary"}
                  size="xs"
                  onClick={() => handleShare(true)}
                  className="absolute right-1 top-1  h-8"
                >
                  <div className="px-2">Copy</div>
                </Button>
              </div>
            </div>
            <div className="space-y-1">
              <div>
                <div className="flex gap-2">
                  <Label>Copy ID</Label>
                </div>
              </div>
              <div className="relative">
                <Input
                  value={id}
                  readOnly
                  id="name"
                  type="text"
                  className="truncate w-full pr-[5em]"
                />
                <Button
                  variant="primary"
                  size="xs"
                  onClick={() => handleShare(false)}
                  className="absolute right-1 top-1 h-8"
                >
                  <div className="px-2">Copy</div>
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </PopoverContent>
    </Popover>
  );
}
