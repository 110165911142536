import { cn } from "@/utils/utils";
import { Link, useLocation, useNavigate } from "react-router-dom";

export function MainNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  const mainNavItems = [
    {
      title: "My Library",
      href: "/",
      paths: ["/flows", "/components"],
    },
    {
      title: "Store",
      href: "/store",
      paths: ["/store"],
    },
    {
      title: "Settings",
      href: "/profile/settings",
      paths: ["/profile/settings", "/profile/appearance", "/profile/api-key"],
    },
  ];
  return (
    <nav
      className={cn("flex items-center space-x-4 lg:space-x-6", className)}
      {...props}
    >
      {mainNavItems.map((item, index) => (
        <Link
          key={index}
          to={item.href}
          className={cn(
            "text-sm font-medium transition-colors hover:text-primary",
            item.paths.some((value) => value === pathname)
              ? ""
              : "text-muted-foreground",
          )}
          onClick={() => {
            navigate(item.href);
          }}
        >
          {item.title}
        </Link>
      ))}
    </nav>
  );
}
