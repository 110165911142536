import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { useToast } from "../../ui/use-toast";
import { Upload } from "lucide-react";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import user from "@/assets/user.webp";
import { User } from "@/types/api";
import { getFile, updateUserProfile, uploadFile } from "@/api/usersApi";
import { AuthContext } from "@/contexts/authContext";

const profileFormSchema = z.object({
  photo: z?.string()?.optional(),
  first_name: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(30, {
      message: "Name must not be longer than 30 characters.",
    }),

  email: z
    .string({
      required_error: "Please select an email to display.",
    })
    .email(),
  username: z.string({
    description: "This is your username. Everyone can see it",
  }),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

export function ProfileForm({ userProfile }: any): JSX.Element {
  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    mode: "onBlur",
  });
  let { getCurrentUser, userData } = useContext(AuthContext);
  const { toast } = useToast();

  function onSubmit() {
    form.trigger().then(() => {
      if (form.formState.isValid) {
        const user = buildUserObj();
        updateUserApi(user);
      }
    });
  }

  function buildUserObj() {
    const user: User = {
      first_name: form.getValues().first_name || userProfile.name,
      email: form.getValues().email || userProfile.email,
      avatar: form.getValues().photo || userProfile.avatar,
    };
    return user;
  }

  function updateUserApi(user: User) {
    updateUserProfile(user).then(
      () => {
        getCurrentUser();
        toast({
          title: "Success.",
          description: "Your profile has been updated.",
        });
      },
      (error) => {
        toast({
          title: "An error has occurred.",
          description: error,
          variant: "destructive",
        });
      }
    );
  }

  const [image, setImage] = useState<string | null>(null);
  const [imageToForm, setImageToForm] = useState<FormData | null>(null);
  const inputImageRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    form.setValue("photo", userProfile?.profile?.profile_picture ?? "");
    form.setValue("first_name", userProfile.first_name);
    form.setValue("email", userProfile.email);
    form.setValue("username", userProfile.username || "");
  }, []);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const fileReader = new FileReader();
      const formData = new FormData();
      const img = e?.target?.files[0];
      formData.append("folder", import.meta.env.BASE_AVATAR_BUCKET_ID!);
      formData.append("file", img);
      setImageToForm(formData);

      uploadFile(formData).then(
        (res) => {
          form.setValue("photo", res["id"]);
        },
        (error) => {
          console.log(error);
        }
      );

      fileReader.onload = (event) => {
        const imageDataUrl = event.target?.result as string;
        setImage(imageDataUrl);
      };
      fileReader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (userData?.avatar) {
      setImage(`${import.meta.env.API_BASE_URL_ASSETS}${userData.avatar}`);
    }
  }, []);

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-8"
          encType="multipart/form-data"
        >
          <FormField
            control={form.control}
            name="photo"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Profile Picture</FormLabel>
                  <FormControl>
                    <>
                      <input
                        ref={inputImageRef}
                        className="hidden"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                      <div
                        onClick={() => inputImageRef.current?.click()}
                        className="relative h-36 w-32 pt-4"
                      >
                        <img
                          className="absolute h-32 w-32 rounded-full object-cover	"
                          src={image ?? user}
                        />
                        <div className="group absolute flex h-32 w-32 cursor-pointer items-center justify-center rounded-full opacity-60 transition duration-300 hover:bg-background">
                          <Upload className="w-16 opacity-0 transition duration-300 group-hover:opacity-100"></Upload>
                        </div>
                      </div>
                    </>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input placeholder="Name" type="text" {...field} />
                </FormControl>
                <FormDescription></FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Username</FormLabel>
                <FormControl>
                  <Input
                    disabled
                    readOnly
                    placeholder="Your public username"
                    type="text"
                    {...field}
                  />
                </FormControl>
                <FormDescription>
                  This is your public username, displayed in projects you share
                  to the store.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    disabled
                    readOnly
                    placeholder="example@example.com"
                    type="email"
                    {...field}
                  />
                </FormControl>
                <FormDescription>
                  Insert a valid email to receive notifications.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button type="submit">Update profile</Button>
        </form>
      </Form>
    </>
  );
}
