import { Button } from "../ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { Icons } from "../ui/icons";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import icon from "../../assets/chain.png";
import { Link, useNavigate } from "react-router-dom";
import { LoginType } from "@/types/api";
import { useState } from "react";
import { sendEmailPasswordRecovery } from "@/api/usersApi";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { toast } from "../ui/use-toast";
import { Spinner } from "../ui/spinner";

export default function ForgotPasswordComponent() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const forgotPasswordFormSchema = z.object({
    email: z
      .string({
        required_error:
          "Please provide the email of the account that you forgot the password.",
      })
      .email(),
  });

  type PasswordFormValues = z.infer<typeof forgotPasswordFormSchema>;

  const form = useForm<PasswordFormValues>({
    resolver: zodResolver(forgotPasswordFormSchema),
    mode: "onBlur",
  });

  const onSubmit = () => {
    form.trigger().then(() => {
      if (form.formState.isValid) {
        sendEmailVerification(form.getValues().email);
      }
    });
  };

  function sendEmailVerification(email: string) {
    setLoading(true);
    sendEmailPasswordRecovery(email).then(
      (res) => {
        setLoading(false);
        toast({
          title: "Verification email sent.",
          description: "Please check your mailbox to reset password.",
        });
        navigate("/login");
      },
      (error) => {
        setLoading(false);
        toast({
          title: "Oops! Something Went Wrong",
          description: error.errors[0].message,
        });
      },
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card className="w-[400px]">
          <CardHeader className="space-y-12">
            <h1 className="flex w-full items-center justify-center gap-3 text-4xl font-semibold">
              <img className="h-12 w-12" src={icon} />
              Langflow Store
            </h1>
          </CardHeader>
          <CardContent className="grid gap-4">
            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          id="email"
                          type="email"
                          placeholder="Your email"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>
          </CardContent>
          <CardFooter className="flex flex-col gap-2">
            <Button type="submit" className="w-full">
              {!loading ? (
                "Send Verification Email"
              ) : (
                <Spinner className="h-6 w-6" />
              )}
            </Button>
            <Button
              className="w-full text-center"
              variant="link"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
}
