import { providerLoginLink } from "@/api/usersApi";
import { Icons } from "../ui/icons";
import { Button } from "../ui/button";

export default function OauthProviders() {
  return (
    <div className="grid grid-cols-2 gap-2">
      <a href={providerLoginLink("github")}>
        <Button variant="outline" type="button" className="w-full">
          <Icons.gitHub className="mr-2 h-4 w-4" />
          Github
        </Button>
      </a>
      <a href={providerLoginLink("google")}>
        <Button variant="outline" type="button" className="w-full">
          <Icons.google className="mr-2 h-4 w-4" />
          Google
        </Button>
      </a>
{/*      <a href={providerLoginLink("discord")}>
        <Button variant="outline" type="button" className="w-full">
          <Icons.discord className="mr-2 h-5 w-5" />
          Discord
        </Button>
      </a>
*/}
    </div>
  );
}
