import Header from "@/components/header";
import PageLayout from "@/components/pageLayout";
import SidebarNav from "@/components/sidebar";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const sidebarNavItems = [
  {
    title: "Profile",
    href: "/profile/settings",
  },

  {
    title: "API Key",
    href: "/profile/api-key",
  },

  {
    title: "Appearance",
    href: "/profile/appearance",
  },
];

export default function ProfileSettingsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  useEffect(() => {
    if (pathname == "/profile/" || pathname == "/profile") {
      navigate("/profile/settings");
    }
  }, [pathname]);
  return (
    <PageLayout
      title="Settings"
      description="Manage your account settings and preferences."
    >
      <div className="flex w-full h-full space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0">
        <aside className="flex h-full flex-col space-y-6 lg:w-1/5">
          <SidebarNav items={sidebarNavItems} />
        </aside>
        <div className="w-full h-full flex-1">
          <Outlet />
        </div>
      </div>
    </PageLayout>
  );
}
