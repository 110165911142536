import Header from "@/components/header";
import PageLayout from "@/components/pageLayout";
import SidebarNav from "@/components/sidebar";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { AuthContext } from "@/contexts/authContext";
import { Group, ToyBrick } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const sidebarNavItems = [
  {
    title: "Flows",
    href: "/flows",
    icon: <Group className="w-5 stroke-[1.5]" />,
  },
  {
    title: "Components",
    href: "/components",
    icon: <ToyBrick className="w-[1.1rem] mx-[0.08rem] stroke-[1.5]" />,
  },
  // {
  //   title: "Add Components",
  //   href: "/add-components",
  // },
];

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const { userData } = useContext(AuthContext);
  const [firstOpening, setFirstOpening] = useState(true);
  useEffect(() => {
    if (userData && firstOpening && userData?.token == undefined) {
      setFirstOpening(false);
      navigate("/profile/api-key");
    }
    if (pathname == "/") {
      navigate("/flows");
    }
  }, [pathname, userData]);

  return (
    <PageLayout
      title="My Library"
      description="See your published flows and components."
    >
      <div className="flex w-full h-full space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0">
        <aside className="flex h-full flex-col space-y-6 lg:w-1/5">
          <SidebarNav items={sidebarNavItems} />
        </aside>
        <div className="w-full h-full flex-1">
          <Outlet />
        </div>
      </div>
    </PageLayout>
  );
};

export default HomePage;
