import { getLoggedUser } from "@/api/usersApi";
import { ProfileForm } from "@/components/profile/profile/profile-form";
import { Separator } from "@/components/ui/separator";
import { toast } from "@/components/ui/use-toast";
import { AuthContext } from "@/contexts/authContext";
import { useContext, useEffect, useState } from "react";

export default function ProfileSettings() {
  const { userData } = useContext(AuthContext);

  return (
    <>
      <div className="space-y-6 pb-8">
        <div>
          <h3 className="text-lg font-medium">Profile</h3>
          <p className="text-sm text-muted-foreground">
            This is how others will see you.
          </p>
        </div>
        <Separator />
        {!userData ? (
          <div>Loading...</div>
        ) : (
          <ProfileForm userProfile={userData} />
        )}
      </div>
    </>
  );
}
