import { Button } from "../ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import icon from "../../assets/chain.png";
import { Link, useNavigate } from "react-router-dom";
import { LoginType } from "@/types/api";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "@/contexts/authContext";
import { onLogin, renewAccessToken } from "@/api/usersApi";
import { toast } from "../ui/use-toast";
import { Spinner } from "../ui/spinner";
import OauthProviders from "../oauthProviders";

export default function LoginComponent() {
  const { login, setUserData, loggingOut } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const waitlistTitle = "Hang tight!";

  const waitlistMessage =
    "We're working hard to get you access to the Langflow Store. Keep an eye on your inbox for good news! 👀";
  useEffect(() => {
    //timeout to give time to the browser to remove the token from the cookies
    setTimeout(() => {
      if (loggingOut) return;
      renewAccessToken()
        .then((res) => {
          if (res.data.data.access_token) {
            login(res.data.data.access_token, "ssotoken");
          }
        })
        .catch((error) => {
          const cause = window.location.search.split("=")[1];
          switch (cause) {
            case "INVALID_PROVIDER":
              toast({
                title: "An error has occurred.",
                description: "This email is already in use",
                variant: "destructive",
              });
              break;
            case "INVALID_CREDENTIALS":
              toast({
                title: "An error has occurred.",
                description:
                  "There was an error with your credentials, if you are using github, please check if your email is public.",
                variant: "destructive",
              });
              break;
            case "NOT_IN_WAITLIST":
              toast({
                title: waitlistTitle,
                description: waitlistMessage,
                variant: "destructive",
              });
              break;
            default:
              //DO not add any toast here
              break;
          }
        });
    }, 500);
  }, []);

  function signIn(log: LoginType) {
    const user: LoginType = {
      username: log.username.trim(),
      password: log.password.trim(),
    };
    setLoading(true);

    onLogin(user)
      .then((user) => {
        setLoading(false);
        login(user.access_token, user.refresh_token);
      })
      .catch((error) => {
        setLoading(false);
        error["errors"]?.forEach((err: any) => {
          if (err["message"] === "An unexpected error occurred.") {
            toast({
              title: waitlistTitle,
              description: waitlistMessage,
            });
          } else
            toast({
              title: "An error has occurred.",
              description: err["message"],
              variant: "destructive",
            });
        });
      });
  }

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    signIn(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card className="w-[400px]">
        <CardHeader className="space-y-12">
          <h1 className="flex w-full items-center justify-center gap-3 text-4xl font-semibold">
            <img className="h-12 w-12" src={icon} />
            Langflow Store
          </h1>
        </CardHeader>
        <CardContent className="grid gap-4">
          <OauthProviders />
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t" />
            </div>
            <div className="relative flex justify-center text-xs uppercase">
              <span className="bg-muted px-2 text-muted-foreground">
                Or continue with
              </span>
            </div>
          </div>
          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input
              type="text"
              name="username"
              placeholder="Email"
              value={formData.username}
              onChange={handleInputChange}
              id="username"
            />
          </div>
          <div className="flex w-full flex-col gap-2">
            <div className="grid gap-2">
              <Label htmlFor="password">Password</Label>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
                id="password"
              />
            </div>
            <Link
              className="mr-1 text-right text-xs text-muted-foreground hover:text-foreground"
              to="/forgot-password"
              tabIndex={-1}
            >
              Forgot password?
            </Link>
          </div>
        </CardContent>
        <CardFooter className="flex flex-col gap-2">
          <Button type="submit" className="w-full">
            {!loading ? "Login" : <Spinner className="h-6 w-6" />}
          </Button>
          <Button
            className="w-full text-center"
            variant="link"
            onClick={() => {
              navigate("/register");
            }}
          >
            Register
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
}
