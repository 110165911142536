import { createContext, useContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { AuthContextType, User } from "../types/api";
import { getLoggedUser, logoutUser, verifyToken } from "@/api/usersApi";
import { useNavigate } from "react-router-dom";
import { error } from "console";

export const initialValue: AuthContextType = {
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
  login: () => {},
  logout: () => {},
  userData: null,
  setUserData: () => {},
  getAuthentication: () => false,
  authenticationErrorCount: 0,
  getCurrentUser: () => {},
  loggingOut: false,
  setLoggingOut: () => {},
};

export const AuthContext = createContext<AuthContextType>(initialValue);

export function AuthProvider({ children }: any): React.ReactElement {
  const cookies = new Cookies();
  const [accessToken, setAccessToken] = useState<string | null>(
    cookies.get("access_tkn_lmkt")
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    cookies.get("refresh_tkn_lmkt")
  );
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loggingOut, setLoggingOut] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [userData, setUserData] = useState<User | null>(null);
  const navigate = useNavigate();

  const statusCodes = new Set([401, 400, 403, "401", "400", "403"]);

  useEffect(() => {
    const storedAccessToken = cookies.get("access_tkn_lmkt");
    if (storedAccessToken) {
      setIsAuthenticated(true);
      getCurrentUser();
    }
  }, []);

  function getAuthentication() {
    const storedRefreshToken = cookies.get("refresh_tkn_lmkt");
    const storedAccess = cookies.get("access_tkn_lmkt");
    const auth = storedAccess && storedRefreshToken ? true : false;
    // const auth = storedAccess ? true : false;
    return auth;
  }

  function login(newAccessToken: string, refreshToken: string) {
    cookies.set("access_tkn_lmkt", newAccessToken, { path: "/" });
    cookies.set("refresh_tkn_lmkt", refreshToken, { path: "/" });
    setAccessToken(newAccessToken);
    setRefreshToken(refreshToken);
    setIsAuthenticated(true);
    setLoggingOut(false);
  }

  function logout(skipRequest?: boolean) {
    const logoutProcess = () => {
      cookies.remove("access_tkn_lmkt", { path: "/" });
      cookies.remove("refresh_tkn_lmkt", { path: "/" });
      setIsAdmin(false);
      setUserData(null);
      setAccessToken(null);
      setRefreshToken(null);
      setIsAuthenticated(false);
      setLoggingOut(true);
      navigate("/login");
    };
    if (skipRequest) {
      setTimeout(() => {
        logoutProcess();
      }, 500);
      return;
    }
    logoutUser(refreshToken)
      .then((_) => {
        setTimeout(() => {
          logoutProcess();
        }, 500);
      })
      .catch((error) => {
        if (statusCodes?.has(error?.response?.status)) {
          setTimeout(() => {
            logoutProcess();
          }, 500);
        }
      });
  }

  async function getCurrentUser() {
    try {
      return Promise.resolve().then(() => {
        return getLoggedUser().then((user) => {
          setUserData(user);
          return user;
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    // !! to convert string to boolean
    <AuthContext.Provider
      value={{
        isAuthenticated: !!accessToken,
        accessToken,
        refreshToken,
        login,
        logout,
        setUserData,
        userData,
        getAuthentication,
        authenticationErrorCount: 0,
        getCurrentUser,
        loggingOut,
        setLoggingOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
