import { ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./authContext";
import { ComponentProvider } from "./componentsContext";
import { TooltipProvider } from "@radix-ui/react-tooltip";

export default function ContextWrapper({ children }: { children: ReactNode }) {
  //element to wrap all context
  return (
    <>
      <BrowserRouter>
        <TooltipProvider>
          <ComponentProvider>
            <AuthProvider>{children}</AuthProvider>
          </ComponentProvider>
        </TooltipProvider>
      </BrowserRouter>
    </>
  );
}
