import { useContext, useEffect, useState } from "react";
import {
  buildFilterConditions,
  deleteComponents,
  getStoreComponents,
} from "@/api/componentApi";
import { NewComponentModal } from "@/modals/new-component";
import { ComponentContext } from "@/contexts/componentsContext";
import { storeComponent, TagsType } from "@/types/api";
import { toast } from "@/components/ui/use-toast";
import { MarketCardComponent } from "@/components/marketCard";
import { SkeletonCardComponent } from "@/components/skeletonCardComponent";
import { AuthContext } from "@/contexts/authContext";
import PaginatorComponent from "@/components/PaginatorComponent";
import CollectionCardComponent from "@/components/cardComponent";

export default function ComponentsComponent({
  is_component = true,
}: {
  is_component?: boolean;
}) {
  const [data, setData] = useState<storeComponent[]>([]);
  const [allData, setAllData] = useState<storeComponent[]>([]);
  const name = is_component ? "Component" : "Flow";
  const [loading, setLoading] = useState(true);
  const { userData } = useContext(AuthContext);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    const start = (pageIndex - 1) * pageSize;
    const end = start + pageSize;
    setData(allData.slice(start, end));
  }, [pageIndex, pageSize, allData]);

  useEffect(() => {
    if (userData) loadComponents();
  }, [userData]);

  const loadComponents = async () => {
    setLoading(true);
    getStoreComponents({ is_component: is_component, filterByUser: true })
      .then((res) => {
        setAllData(
          res!.results.sort(
            (a, b) =>
              new Date(b?.date_created!).getTime() -
              new Date(a?.date_created!).getTime()
          )
        );
        setTotalRowsCount(res!.results.length);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleDelete = async (data: storeComponent) => {
    setLoading(true);
    deleteComponents(data.id)
      .then((res) => {
        toast({
          title: "Success.",
          description: `Your ${name} has been deleted.`,
        });
        loadComponents();
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "An error has occurred.",
          description: err?.errors[0]?.message,
        });
      });
  };

  return (
    <>
      <div className="flex h-full w-full flex-col justify-between">
        <div className="flex w-full flex-col gap-4">
          {!loading && data.length === 0 ? (
            <div className="w-full mt-6 flex justify-center items-center text-center">
              <div className="flex-max-width h-full flex-col">
                <div className="flex w-full flex-col gap-4">
                  <div className="grid w-full gap-4">
                    Flows and components can be created and shared using
                    Langflow.
                  </div>
                  <div className="flex w-full align-center justify-center gap-1">
                    <span>New?</span>
                    <span className="transition-colors hover:text-muted-foreground">
                      <a
                        className="underline"
                        target="_blank"
                        href="https://github.com/logspace-ai/langflow"
                      >
                        Start Here
                      </a>
                      .
                    </span>
                    <span className="animate-pulse">🚀</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="grid w-full gap-4 md:grid-cols-2 lg:grid-cols-2">
              {!loading || data?.length > 0 ? (
                data?.map((item, idx) => (
                  <CollectionCardComponent
                    onDelete={() => {
                      handleDelete(item);
                    }}
                    key={idx + item.id}
                    data={item}
                    disabled={loading}
                  />
                ))
              ) : (
                <>
                  <SkeletonCardComponent />
                  <SkeletonCardComponent />
                </>
              )}
            </div>
          )}
        </div>
        {!loading && allData.length > 0 && (
          <div className="relative py-6">
            <PaginatorComponent
              storeComponent={true}
              pageIndex={pageIndex}
              pageSize={pageSize}
              rowsCount={[10, 20, 50, 100]}
              totalRowsCount={allData.length}
              paginate={(pageSize, pageIndex) => {
                setPageIndex(pageIndex);
                setPageSize(pageSize);
              }}
            ></PaginatorComponent>
          </div>
        )}
      </div>
    </>
  );
}
