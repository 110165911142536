import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useContext, useEffect, useRef, useState } from "react";
import { Check, Copy, Trash2 } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { AuthContext } from "@/contexts/authContext";
import { saveApiKey } from "@/api/usersApi";

export function ApiForm() {
  let { userData, getCurrentUser } = useContext(AuthContext);
  const [apiKey, setApiKey] = useState<string>("");
  const [generated, setGenerated] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [textCopied, setTextCopied] = useState(true);

  const handleCopyClick = async () => {
    if (apiKey) {
      await navigator.clipboard.writeText(apiKey);
      inputRef?.current?.focus();
      inputRef?.current?.select();

      toast({
        title: "Success!",
        description: "Your key has been copied.",
      });

      setTextCopied(false);

      setTimeout(() => {
        setTextCopied(true);
      }, 3000);
    }
  };

  function handleGenerateApiKey() {
    saveApiKey(userData?.id!).then(
      (res) => {
        getCurrentUser();
        setApiKey(res ?? "");
        setGenerated(true);
      },
      () => {
        toast({
          title: "Error!",
          description: "Sorry an error has occurred, please try again.",
          variant: "destructive",
        });
      }
    );
  }

  return (
    <div className="space-y-8">
      <div className="space-y-4">
        <p className="text-sm text-muted-foreground">
          Please save this secret key somewhere safe and accessible. For
          security reasons, <b>you won't be able to view it again</b> through
          your Langflow account. If you lose this secret key, you'll need to
          generate a new one.
        </p>
        <div className="flex gap-4">
          <Input
            ref={inputRef}
            value={
              apiKey !== ""
                ? apiKey
                : userData?.token
                ? "You already have an API key."
                : "Generate a new API key."
            }
            onChange={(e) => setApiKey(e.target.value)}
            id="description"
            className={"w-96" + (apiKey === "" ? " cursor-default" : "")}
            readOnly
          />
          {generated && (
            <div className="self-center">
              {textCopied ? (
                <Copy
                  onClick={() => {
                    handleCopyClick();
                  }}
                  name="Copy"
                  className="h-4 w-4 cursor-pointer"
                />
              ) : (
                <Check name="Check" className="h-4 w-4" />
              )}
            </div>
          )}
        </div>
      </div>
      <Button
        type="button"
        onClick={() => {
          handleGenerateApiKey();
        }}
      >
        Generate New
      </Button>
    </div>
  );
}
