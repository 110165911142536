import { MainNav } from "./main-nav";
import { UserNav } from "./user-nav";
import logo from "../../assets/chain.png";

export default function Header() {
  return (
    <div className="w-full border-b bg-muted">
      <div className="mx-auto flex h-16 px-16 items-center ">
        <img src={logo} alt="logo" className="h-6 w-6" />
        <span className="ml-3 text-xl font-bold">Langflow Store</span>
        <MainNav className="mx-6 ml-12" />
        <div className="ml-auto flex items-center space-x-4">
          <UserNav />
        </div>
      </div>
    </div>
  );
}
