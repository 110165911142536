import { Route, Routes } from "react-router-dom";
import { LoginPage } from "./pages/login/login-page";
import { ThemeProvider } from "./providers/theme-provider";
import HomePage from "./pages/home-page";
import { RegisterPage } from "./pages/login/register-page";
import { ForgotPasswordPage } from "./pages/login/forgot-password-page";
import ProfileSettingsPage from "./pages/profile/profile-settings-page";
import ApiSettings from "./components/profile/api/api-settings";
import { AppearanceSettings } from "./components/profile/appearance/appearance-settings";
import ProfileSettings from "./components/profile/profile/profile-settings";
import PasswordSettings from "./components/profile/password/password-settings";
import { NotificationsSettings } from "./components/profile/notifications/notifications-settings";
import { ProtectedRoute } from "./components/authGuard";
import { ProtectedLoginRoute } from "./components/authLoginGuard";
import { Toaster } from "./components/ui/toaster";
import ComponentsComponent from "./components/home/components";
import { NewPasswordPage } from "./pages/login/new-password-page";
import { useContext } from "react";
import { AuthContext } from "./contexts/authContext";
import { UpdateUsernameModal } from "./modals/updateUsername";
import StorePage from "./pages/store-page";

function App() {
  const { userData, getCurrentUser } = useContext(AuthContext);
  return (
    <>
      {userData && !userData?.username && <UpdateUsernameModal />}
      <div className="h-full w-full overflow-y-auto scroll-stable">
        <ThemeProvider defaultTheme="auto">
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            >
              <Route path="flows" element={<ComponentsComponent key="flows" is_component={false} />} />
              <Route path="components" element={<ComponentsComponent key="components" />} />
            </Route>

            <Route
              path="/forgot-password"
              element={
                <ProtectedLoginRoute>
                  <ForgotPasswordPage />
                </ProtectedLoginRoute>
              }
            />
            <Route
              path={import.meta.env.RESET_PASSWORD_PATH}
              element={
                <ProtectedLoginRoute>
                  <NewPasswordPage />
                </ProtectedLoginRoute>
              }
            />

            <Route
              path="/login"
              element={
                <ProtectedLoginRoute>
                  <LoginPage />
                </ProtectedLoginRoute>
              }
            />
            <Route
              path="/register"
              element={
                <ProtectedLoginRoute>
                  <RegisterPage />
                </ProtectedLoginRoute>
              }
            />
            <Route
              path="/store"
              element={
                <ProtectedRoute>
                  <StorePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/store/:id"
              element={
                <ProtectedRoute>
                  <StorePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile/"
              element={
                <ProtectedRoute>
                  <ProfileSettingsPage />
                </ProtectedRoute>
              }
            >
              <Route
                path="settings"
                element={
                  <ProtectedRoute>
                    <ProfileSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="api-key"
                element={
                  <ProtectedRoute>
                    <ApiSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="appearance"
                element={
                  <ProtectedRoute>
                    <AppearanceSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="notifications"
                element={
                  <ProtectedRoute>
                    <NotificationsSettings />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Routes>
        </ThemeProvider>
      </div>
    </>
  );
}

export default App;
