import ReactDOM from "react-dom/client";
import App from "./App";
import ContextWrapper from "./contexts";
import "./index.css";
import { ApiInterceptor } from "./api";
import { Toaster } from "./components/ui/toaster";

// @ts-ignore
import "./styles/applies.css";

import ReactGA from "react-ga4";

import * as Sentry from "@sentry/react";

// Check if SENTRY_DSN is set
if (import.meta.env.SENTRY_DSN === undefined) {
  console.warn(
    "SENTRY_DSN is not set. Sentry is disabled. See .env.example for more information.",
  );
} else {
  Sentry.init({
    dsn: import.meta.env.SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // www.langflow.store or langflow.store
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/langflow\.store/,
          /^https:\/\/www\.langflow\.store/,
        ],
      }),
    new Sentry.Replay({maskAllText:false,maskAllInputs:false,blockAllMedia:false,networkDetailAllowUrls:[/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/],networkCaptureBodies:true}),
    ],
    // Performance Monitoring
    tracesSampleRate: import.meta.env.SENTRY_TRACES_SAMPLE_RATE ?? 0.9,
    // Session Replay
    replaysSessionSampleRate:
      import.meta.env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? 0.1,
    replaysOnErrorSampleRate:
      import.meta.env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ?? 1.0,
  });
}

if (import.meta.env.G_TAG === undefined) {
  console.warn(
    "G_TAG is not set. Sentry is disabled. See .env.example for more information.",
  );
} else {
  ReactGA.initialize(import.meta.env.G_TAG);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <ContextWrapper>
    <ApiInterceptor />
    <Toaster />
    <App />
  </ContextWrapper>,
);
