import { createContext, useState } from "react";
import { ComponentContextType, storeComponent, User } from "../types/api";

export const initialValue: ComponentContextType = {
  componentList: [],
  setComponentList: () => {},
};

export const ComponentContext =
  createContext<ComponentContextType>(initialValue);

export function ComponentProvider({ children }: any): React.ReactElement {
  const [componentList, setComponentList] = useState<storeComponent[]>([]);

  return (
    <ComponentContext.Provider
      value={{
        componentList,
        setComponentList,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
}
