import { StoreComponentResponse, TagFilter } from "@/types/api";
import { api } from ".";

/**
 * Fetches all objects from the API endpoint.
 * @returns {Promise<AxiosResponse<APIObjectType>>} A promise that resolves to an AxiosResponse containing all the objects.
 **/

export async function getStoreComponents({
  component_id = null,
  page = 1,
  limit = 9999999,
  is_component = null,
  sort = "-count(liked_by)",
  tags = [] || null,
  liked = null,
  isPrivate = null,
  search = null,
  filterByUser = null,
}: {
  component_id?: string | null;
  page?: number;
  limit?: number;
  is_component?: boolean | null;
  sort?: string;
  tags?: string[] | null;
  liked?: boolean | null;
  isPrivate?: boolean | null;
  search?: string | null;
  filterByUser?: boolean | null;
}): Promise<StoreComponentResponse | undefined> {
  try {
    let url = `${import.meta.env.API_BASE_URL}items/components`;
    const queryParams: any = [
      "fields=id,name,description,user_created.username,is_component,tags.tags_id.name,tags.tags_id.id,count(liked_by),count(downloads),metadata,private,last_tested_version",
    ];
    queryParams.push(
      `filter=${encodeURIComponent(
        JSON.stringify({
          _and: buildFilterConditions({
            search: search,
            is_private: isPrivate,
            tags: tags,
            isComponent: is_component,
            component_id: component_id,
            filterByUser: filterByUser,
            liked: liked,
          }),
        })
      )}`
    );
    if (sort !== undefined && sort !== null) {
      queryParams.push(`sort=${sort}`);
    } else {
      queryParams.push(`sort=-count(liked_by)`); // default sort
    }
    if (page !== undefined) {
      queryParams.push(`page=${page ?? 1}`);
    }
    if (limit !== undefined) {
      queryParams.push(`limit=${limit ?? 9999999}`);
    }
    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    const response = await api.get(url);

    if (response?.status !== 200) {
      throw new Error("API request failed");
    }

    url = `${import.meta.env.API_BASE_URL}items/components`;

    queryParams.push(
      `aggregate=${encodeURIComponent(JSON.stringify({ count: "*" }))}`
    );

    if (page > 1) {
      const index = queryParams.indexOf(`page=${page ?? 1}`);
      queryParams.splice(index, 1);
      queryParams.push(`page=1`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    const responseCount = await api.get(url);

    if (responseCount.status !== 200) {
      throw new Error("API request failed");
    }

    url = `${import.meta.env.API_BASE_URL}items/components`;

    const componentIds = response.data.data.map(
      (component: any) => component.id
    );
    var likes: string[] = [];

    if (componentIds.length !== 0) {
      const likeQueryParams = [
        "fields=id",
        `filter=${encodeURIComponent(
          JSON.stringify({
            _and: [
              { id: { _in: componentIds } },
              { liked_by: { directus_users_id: { _eq: "$CURRENT_USER" } } },
            ],
          })
        )}`,
      ];

      const responseLikes = await api.get(
        `${url}?${likeQueryParams.join("&")}`
      );
      if (responseLikes.status !== 200) {
        throw new Error("API request failed");
      }

      likes = responseLikes.data.data.map((like: any) => like.id);
    }
    const components = response.data.data.map((component: any) => ({
      ...component,
      liked_by_user: likes.includes(component.id),
      tags: component.tags.map((tag: any) => tag.tags_id),
    }));
    return {
      count: responseCount?.data?.data[0]?.count,
      results: components,
      authorized: true,
    };
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function getStoreTags() {
  try {
    const res = await api.get(`${import.meta.env.API_BASE_URL}items/tags`);
    if (res?.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function deleteComponents(id: string) {
  try {
    const response = await api.delete(
      `${import.meta.env.API_BASE_URL}items/components/${id}`
    );

    if (response?.status === 200) {
      const data = response.data;
      return data["data"];
    }
  } catch (error) {
    throw error;
  }
}
/**
 * Builds the filter conditions for the API request.
 * @param query The search query.
 * @returns {any} The filter conditions for the API request.
 * @example
 **/
function buildSearchFilterConditions(query: string) {
  //use python string format for json parse facility
  return {
    _or: [
      { name: { _icontains: query } },
      { description: { _icontains: query } },
      { tags: { tags_id: { name: { _icontains: query } } } },
    ],
  };
}

/**
 * Builds the filter conditions for the API request.
 * @param search The search query.
 * @param status The status to filter by.
 * @param tags The tags to filter by.
 * @param isComponent Whether to filter by components or flows.
 * @param filterByUser Whether to filter by the user.
 * @param liked Whether to filter by liked.
 * @param userData The user data.
 * @returns {any[]} The filter conditions for the API request.
 * @throws {Error} If the user data is not available.
 * @example
 * const filterConditions = buildFilterConditions(
 *  search,
 * status,
 * tags,
 * isComponent,
 * filterByUser,
 * liked,
 * userData,
 * );
 **/
export function buildFilterConditions({
  search,
  is_private,
  tags,
  isComponent,
  component_id,
  filterByUser,
  liked,
}: {
  search?: string | null;
  is_private?: boolean | null;
  tags?: string[] | null;
  isComponent?: boolean | null;
  filterByUser?: boolean | null;
  component_id?: string | null;
  liked?: boolean | null;
}): any[] {
  const filterConditions = [];
  if (search) {
    const searchConditions = buildSearchFilterConditions(search);
    filterConditions.push(searchConditions);
  }

  if (is_private) {
    filterConditions.push({ private: { _eq: is_private } });
  }

  if (component_id) {
    filterConditions.push({ id: { _eq: component_id } });
  }

  if (tags && tags.length > 0) {
    const tagsFilter = buildTagsFilter(tags);
    filterConditions.push(tagsFilter);
  }

  if (isComponent !== undefined && isComponent !== null) {
    filterConditions.push({ is_component: { _eq: isComponent } });
  }

  if (liked) {
    const likedFilter = buildLikedFilter();
    filterConditions.push(likedFilter);
  }

  if (filterByUser) {
    filterConditions.push({ user_created: { _eq: "$CURRENT_USER" } });
  } else {
    filterConditions.push({ private: { _eq: false } });
  }

  return filterConditions;
}

/**
 * Builds the filter condition for the tags field.
 * @param tags The tags to filter by.
 * @returns {any} The filter condition for the tags field.
 * @example
 **/
function buildTagsFilter(tags: string[]): any {
  const tagsFilter = { tags: { _and: [] as TagFilter[] } };
  tags.forEach((tag) => {
    tagsFilter.tags._and.push({ _some: { tags_id: { name: { _eq: tag } } } });
  });
  return tagsFilter;
}

/**
 * Builds the filter condition for the liked_by field.
 * @param userData The user data.
 * @returns {any} The filter condition for the liked_by field.
 * @throws {Error} If the user data is not available.
 * @example
 **/
function buildLikedFilter(): any {
  return { liked_by: { directus_users_id: { _eq: "$CURRENT_USER" } } };
}

export async function getComponent(componentId: string) {
  const default_fields = ["id", "name", "data", "is_component"].join(",");
  try {
    let url = `${import.meta.env.API_BASE_URL}items/components`;
    const idFilter = `{ "id": {"_in": ["${componentId}"] } }`;
    // Add the serialized filters to the URL
    const queryParams = [
      `fields=${default_fields}`,
      `filter=${encodeURIComponent(idFilter)}`,
    ];

    // Construct the final URL
    url += `?${queryParams.join("&")}`;

    // Make the HTTP request
    const response = await api.get(url); // or use your API request method
    if (response?.status !== 200) {
      throw new Error("API request failed");
    }
    return response.data.data[0];
  } catch (error) {
    throw error;
  }
}

export async function getLikesComponent(componentId: string) {
  const params = "fields=" + ["id", "count(liked_by)"].join();
  try {
    let url = `${import.meta.env.API_BASE_URL}items/components/${componentId}`;

    // Construct the final URL
    url += `?${params}`;

    // Make the HTTP request
    const response = await api.get(url); // or use your API request method
    if (response?.status !== 200) {
      throw new Error("API request failed");
    }
    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export async function downloadComponent(componentId: string) {
  try {
    const response = await api.post(
      `${import.meta.env.LANGFLOW_DOWNLOAD_WEBHOOK_URL}`,
      {
        component_id: componentId,
      }
    );

    if (response?.status === 200) {
      const data = response.data;
      return data["data"];
    }
  } catch (error) {
    throw error;
  }
}
