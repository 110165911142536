import { Button } from "../ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { Icons } from "../ui/icons";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import icon from "../../assets/chain.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LoginType } from "@/types/api";
import { useState } from "react";
import { resetPassword, sendEmailPasswordRecovery } from "@/api/usersApi";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { toast } from "../ui/use-toast";
import { Spinner } from "../ui/spinner";

export default function NewPasswordComponent() {
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(document.location.search);

  const token = searchParams.get("token");

  const [loading, setLoading] = useState(false);

  const newPasswordFormSchema = z
    .object({
      password: z.string().min(8, {
        message: "Password must be at least 8 characters.",
      }),
      confirmPassword: z.string().min(8, {
        message: "Password must be at least 8 characters.",
      }),
    })
    .superRefine(({ password, confirmPassword }, ctx) => {
      if (confirmPassword !== password) {
        ctx.addIssue({
          path: ["confirmPassword"],
          code: "custom",
          message: "The passwords did not match",
        });
      }
    });

  type PasswordFormValues = z.infer<typeof newPasswordFormSchema>;

  const form = useForm<PasswordFormValues>({
    resolver: zodResolver(newPasswordFormSchema),
    mode: "onBlur",
  });

  const onSubmit = () => {
    form.trigger().then(() => {
      if (form.formState.isValid) {
        sendEmailVerification(form.getValues().password);
      }
    });
  };

  function sendEmailVerification(password: string) {
    setLoading(true);
    resetPassword(password, token!).then(
      (res) => {
        setLoading(false);
        toast({
          title: "New password set.",
          description: "Please login with your new password.",
        });
        navigate("/login");
      },
      (error) => {
        setLoading(false);
        error.errors.forEach((err: any) => {
          toast({
            title: "An error occurred.",
            description: err["message"],
          });
        });
      },
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card className="w-[400px]">
          <CardHeader className="space-y-12">
            <h1 className="flex w-full items-center justify-center gap-3 text-4xl font-semibold">
              <img className="h-12 w-12" src={icon} />
              Langflow Store
            </h1>
          </CardHeader>
          <CardContent className="grid gap-4">
            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <Input
                          id="password"
                          type="password"
                          placeholder="Your password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Confirm password</FormLabel>
                      <FormControl>
                        <Input
                          id="password2"
                          type="password"
                          placeholder="Confirm password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>
          </CardContent>
          <CardFooter className="flex flex-col gap-2">
            <Button type="submit" className="w-full">
              {!loading ? "Set New Password" : <Spinner className="h-6 w-6" />}
            </Button>
            <Button
              className="w-full text-center"
              variant="link"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
}
