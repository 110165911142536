import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogClose } from "@radix-ui/react-dialog";
import { Trash2 } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import user from "@/assets/user.webp";
import { User } from "@/types/api";
import { updateUserProfile } from "@/api/usersApi";
import { toast } from "@/components/ui/use-toast";
import { AuthContext } from "@/contexts/authContext";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

export function UpdateUsernameModal({}: {}) {
  const [open, setOpen] = useState(true);
  const { getCurrentUser } = useContext(AuthContext);

  const profileFormSchema = z.object({
    username: z
      .string()
      .trim()
      .min(1, {
        message: "Name must be at least 1 character.",
      })
      .max(39, {
        message: "Name must not be longer than 39 characters.",
      })
      .regex(/^[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,38}$/i, {
        message: "Your username must contain only letters, numbers or hyphens.",
      }),
  });

  type ProfileFormValues = z.infer<typeof profileFormSchema>;

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    mode: "onBlur",
  });

  function updateUsername(user: User) {
    updateUserProfile(user).then(
      () => {
        toast({
          title: "Success.",
          description: "Your username has been set.",
        });
        getCurrentUser();
        setOpen(false);
      },
      (error) => {
        if (error?.errors?.length > 0) {
          error?.errors?.forEach((error: any) => {
            toast({
              title: "An error has occurred.",
              variant: "destructive",

              description:
                error.message ==
                'Value for field "username" in collection "directus_users" has to be unique.'
                  ? "username already taken, please try again"
                  : error.message,
            });
          });
        } else {
          toast({
            title: "An error has occurred.",
            variant: "destructive",
            description: error?.message ?? "An unexpected error occurred.",
          });
        }
      }
    );
  }
  function onSubmit() {
    form.trigger().then(() => {
      if (form.formState.isValid) {
        const user = { username: form.getValues().username };
        updateUsername(user);
      }
    });
  }

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <div className="flex">Before we start...</div>
          </DialogTitle>
          <DialogDescription>
            <p>Let's create a username.</p>
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-8"
            encType="multipart/form-data"
          >
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Username</FormLabel>
                  <FormControl>
                    <Input placeholder="Your public username" {...field} type="text" />
                  </FormControl>
                  <FormDescription>
                    <span className="text-xs">
                      This username will appear to others when you publish
                      components and flows.
                      <br></br>
                      <b>Be careful, you can't change it later.</b>
                    </span>{" "}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button type="submit">Confirm</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
