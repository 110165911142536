import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import avatar from "../../assets/user.webp";
import { AuthContext } from "@/contexts/authContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export function UserNav() {
  let { logout, userData } = useContext(AuthContext);
  const navigate = useNavigate();

  const mainNavItems = [
    {
      title: "Settings",
      href: "/profile/settings",
    },
    {
      title: "API Key",
      href: "/profile/api-key",
    },
  ];

  return (
    <>
      <div className="beta-badge-wrapper">
        <div className="beta-badge-content cursor-default">BETA</div>
      </div>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="relative h-8 w-8 rounded-full">
            <Avatar className="h-8 w-8">
              <AvatarImage
                src={
                  userData?.avatar
                    ? `${import.meta.env.API_BASE_URL_ASSETS}${userData.avatar}`
                    : avatar
                }
                alt="@shadcn"
              />
              <AvatarFallback>
                <img src={avatar} />
              </AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end" forceMount>
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-medium leading-none">
                {userData?.first_name ? userData?.first_name : "User"}
              </p>
              <p className="text-xs leading-none text-muted-foreground">
                {userData?.email}
              </p>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />

          <DropdownMenuGroup>
            {mainNavItems.map((item, index) => (
              <DropdownMenuItem
                key={index}
                className="cursor-pointer"
                onClick={() => {
                  navigate(item.href);
                }}
              >
                {item.title}
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              logout();
            }}
            className="cursor-pointer"
          >
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
