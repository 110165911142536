import { Button } from "../ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { Icons } from "../ui/icons";
import { Input } from "../ui/input";
import icon from "../../assets/chain.png";
import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { registerNewUser } from "@/api/usersApi";
import { NewUser } from "@/types/api";
import { toast } from "../ui/use-toast";
import { Spinner } from "../ui/spinner";
import { useState } from "react";
import OauthProviders from "../oauthProviders";

export function RegisterComponent() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const registerFormSchema = z
    .object({
      first_name: z
        .string()
        .min(2, {
          message: "First name must be at least 2 characters.",
        })
        .max(30, {
          message: "First name must not be longer than 30 characters.",
        }),
      last_name: z
        .string()
        .min(2, {
          message: "First name must be at least 2 characters.",
        })
        .max(30, {
          message: "First name must not be longer than 30 characters.",
        }),
      email: z
        .string({
          required_error: "Please select an email to display.",
        })
        .email(),
      username: z
        .string({
          required_error: "Please select a valid username",
        })
        .trim(),
      password: z.string().min(8, {
        message: "Password must be at least 8 characters.",
      }),
      confirmPassword: z.string().min(8, {
        message: "Password must be at least 8 characters.",
      }),
    })
    .superRefine(({ password, confirmPassword }, ctx) => {
      if (confirmPassword !== password) {
        ctx.addIssue({
          path: ["confirmPassword"],
          code: "custom",
          message: "The passwords did not match",
        });
      }
    });

  type PasswordFormValues = z.infer<typeof registerFormSchema>;

  const form = useForm<PasswordFormValues>({
    resolver: zodResolver(registerFormSchema),
    mode: "onBlur",
  });

  function onSubmit() {
    setLoading(true);
    form.trigger().then(() => {
      if (form.formState.isValid) {
        const newUser = buildNewUserObj();

        registerNewUser(newUser).then(
          () => {
            toast({
              title: "Success.",
              description: "Your account has been created.",
            });
            setLoading(false);
            navigate("/login");
          },
          (error) => {
            console.log(error);
            setLoading(false);
            error["errors"]?.forEach((err: any) => {
              toast({
                title: "An error has occurred.",
                description: err["message"],
              });
            });
          },
        );
      }
    });
  }

  function buildNewUserObj() {
    const newUser: NewUser = {
      first_name: form.getValues().first_name,
      last_name: form.getValues().last_name,
      email: form.getValues().email.toLowerCase(),
      password: form.getValues().password,
      role: import.meta.env.ROLE_MARKETPLACEUSER_KEY!,
      username: form.getValues().username,
    };
    return newUser;
  }

  return (
    <>
      <div className="space-y-2">
        <Card className="w-[400px] ">
          <CardHeader className="space-y-12">
            <h1 className="flex w-full items-center justify-center gap-3 text-4xl font-semibold">
              <img className="h-8 w-8" src={icon} />
              Langflow Store
            </h1>
          </CardHeader>
          <CardContent className="grid gap-4">
            <OauthProviders />

            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t" />
                  </div>
                  <div className="relative flex justify-center text-xs uppercase">
                    <span className="bg-background px-2 text-muted-foreground">
                      Or register with
                    </span>
                  </div>
                </div>

                <div className="grid gap-2">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input
                              id="email"
                              type="email"
                              placeholder="example@example.com"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </div>

                <div className="flex gap-2 mt-2">
                  <FormField
                    control={form.control}
                    name="first_name"
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>First Name</FormLabel>
                          <FormControl>
                            <Input
                              id="first_name"
                              type="text"
                              placeholder="Your first name"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name="last_name"
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Last Name</FormLabel>
                          <FormControl>
                            <Input
                              id="last_name"
                              type="text"
                              placeholder="Your last name"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </div>
                <div className="grid gap-2 mt-2">
                  <FormField
                    control={form.control}
                    name="username"
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Public username</FormLabel>
                          <FormControl>
                            <Input
                              id="username"
                              type="text"
                              placeholder="Your public username"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </div>
                <div className="flex w-full flex-col gap-2 mt-2">
                  <div className="grid gap-2">
                    <FormField
                      control={form.control}
                      name="password"
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel>Password</FormLabel>
                            <FormControl>
                              <Input
                                id="password"
                                type="password"
                                placeholder="Your password"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />

                    <FormField
                      control={form.control}
                      name="confirmPassword"
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel>Confirm password</FormLabel>
                            <FormControl>
                              <Input
                                id="password"
                                type="password"
                                placeholder="Confirm password"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                </div>
              </form>
            </Form>
          </CardContent>
          <CardFooter className="flex flex-col gap-2">
            <Button
              onClick={form.handleSubmit(onSubmit)}
              className="w-full"
              type="submit"
            >
              {!loading ? "Register" : <Spinner className="h-6 w-6" />}
            </Button>
            <Button
              className="w-full text-center"
              variant="link"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </Button>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}
