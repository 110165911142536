import { BASE_URL_API } from "@/constants/constants";
import { api } from ".";
import { LoginType, NewUser, User } from "@/types/api";
import { getLikesComponent } from "./componentApi";

/**
 * Fetches all objects from the API endpoint.
 * @returns {Promise<AxiosResponse<APIObjectType>>} A promise that resolves to an AxiosResponse containing all the objects.
 **/

export async function onLogin(user: LoginType) {
  try {
    const response = await api.post(
      `${import.meta.env.API_BASE_URL}auth/login/`,
      {
        email: user.username,
        password: user.password,
      }
    );

    if (response.status === 200) {
      const data = response.data;
      return data.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function renewAccessToken(token?: string) {
  try {
    if (token && token !== "ssotoken") {
      return await api.post(`${import.meta.env.API_BASE_URL}auth/refresh`, {
        refresh_token: token,
      });
    } else {
      return await api.post(
        `${import.meta.env.API_BASE_URL}auth/refresh`,
        { mode: "cookie" },
        { withCredentials: true }
      );
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function verifyToken(token: string) {
  try {
    if (token) {
      const res = await api.post(`${BASE_URL_API}/users/auth/token/verify/`, {
        token: token,
      });

      if (res?.status === 200) {
        return res.data;
      }
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function getLoggedUser() {
  try {
    const res = await api.get(`${import.meta.env.API_BASE_URL}users/me/`);

    if (res?.status === 200) {
      return res.data["data"];
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
  return null;
}

export async function logoutUser(refresh_token: string | null) {
  try {
    const res = await api.post(`${import.meta.env.API_BASE_URL}auth/logout`, {
      refresh_token: refresh_token,
    }).catch(e=>{throw e;});

    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
  return null;
}

export async function deleteUser(user_id: string) {
  try {
    const res = await api.delete(`${BASE_URL_API}users/${user_id}`);
    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function updateUser(user_id: string, user: User) {
  try {
    const res = await api.patch(`${BASE_URL_API}users/${user_id}`, user);
    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function resetPassword(password: string, token: string) {
  try {
    const res = await api.post(
      `${import.meta.env.API_BASE_URL}auth/password/reset/`,
      {
        token: token,
        password: password,
      }
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function changePassword(
  new_password1: string,
  new_password2: string,
  old_password: string
) {
  try {
    const res = await api.post(`${BASE_URL_API}/users/auth/password/change/`, {
      new_password1: new_password1,
      new_password2: new_password2,
      old_password: old_password,
    });
    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function confirmPasswordReset(
  new_password1: string,
  new_password2: string,
  uid: string,
  token: string
) {
  try {
    const res = await api.patch(`${BASE_URL_API}/users/auth/password/change/`, {
      new_password1: new_password1,
      new_password2: new_password2,
      uid: uid,
      token: token,
    });
    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function resentEmail(email: string) {
  try {
    const res = await api.patch(
      `${BASE_URL_API}/users/auth/registration/resend-email/`,
      {
        email: email,
      }
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function verifyEmail(key: string) {
  try {
    const res = await api.patch(
      `${BASE_URL_API}/users/auth/registration/resend-email/`,
      {
        key: key,
      }
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function getUserProfile(username: string) {
  try {
    const res = await api.get(`${BASE_URL_API}/api/users/${username}/`);
    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function updateUserProfile(userProfile: User) {
  try {
    const res = await api.patch(
      `${import.meta.env.API_BASE_URL}users/me`,
      userProfile,
      {}
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function registerNewUser(newUser: NewUser) {
  try {
    const res = await api.post(
      `${import.meta.env.API_BASE_URL}users/`,
      newUser
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}
export async function uploadFile(file: FormData) {
  try {
    const res = await api.post(`${import.meta.env.API_BASE_URL}files`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (res?.status === 200) {
      return res.data["data"];
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function getFile(file: string) {
  try {
    const res = await api.get(`${import.meta.env.API_BASE_URL}files/${file}`);
    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function sendEmailPasswordRecovery(email: string) {
  try {
    const res = await api.post(
      `${import.meta.env.API_BASE_URL}auth/password/request`,
      {
        email: email,
        reset_url:
          import.meta.env.BASE_URL + import.meta.env.RESET_PASSWORD_PATH,
      }
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function saveApiKey(userId: string): Promise<string | undefined> {
  try {
    const token = await generateApiKey();
    const user = {
      token: token["data"],
    };
    const res = await api.patch(
      `${import.meta.env.API_BASE_URL}users/me`,
      user,
      {}
    );
    if (res?.status === 200) {
      return token["data"];
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function generateApiKey(): Promise<any> {
  try {
    const res = await api.get(
      `${import.meta.env.API_BASE_URL}utils/random/string`
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export async function postLikeComponent(id: string): Promise<any> {
  try {
    const res = await api.post(import.meta.env.LANGFLOW_LIKE_WEBHOOK_URL, {
      component_id: id,
      user_id: "{$CURRENT_USER}",
    });
    var liked_by_user = typeof res.data === "number" ? false : true;
    var likes_count = 0;
    const responseLikes = await getLikesComponent(id);
    likes_count = responseLikes.liked_by_count;
    return { data: { liked_by_user, likes_count } };
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
}

export function providerLoginLink(provider: string): string {
  return `${import.meta.env.API_BASE_URL}auth/login/${provider}?redirect=${
    import.meta.env.BASE_URL
  }login`;
}
