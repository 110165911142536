import { AuthContext } from "@/contexts/authContext";
import axios, { AxiosError, AxiosInstance } from "axios";
import { useContext, useEffect } from "react";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { renewAccessToken } from "./usersApi";

// Create a new Axios instance
const api: AxiosInstance = axios.create({
  baseURL: "",
  withCredentials: true,
});

function ApiInterceptor() {
  let { accessToken, login, logout, authenticationErrorCount, refreshToken } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (error?.response?.status === 401) {
          const refreshToken = cookies.get("refresh_tkn_lmkt");
          if (refreshToken) {
            authenticationErrorCount = authenticationErrorCount + 1;
            if (authenticationErrorCount > 3) {
              authenticationErrorCount = 0;
              logout(true);
            }
            const res = await renewAccessToken(refreshToken);
            if (res?.data?.data?.access_token) {
              login(
                res?.data?.data?.access_token,
                res?.data?.data?.refresh_token
              );
            }

            try {
              if (error?.config?.headers) {
                delete error.config.headers["Authorization"];
                error.config.headers["Authorization"] = `Bearer ${cookies.get(
                  "access_tkn_lmkt"
                )}`;
                const response = await axios.request(error.config);
                return response;
              }
            } catch (error) {
              if (
                axios.isAxiosError(error) &&
                error?.response?.status === 401
              ) {
                logout();
              }
            }
          }

          if (!refreshToken && error?.config?.url?.includes("login")) {
            return (
              Promise.reject(error["response"]!["data"]) ??
              Promise.reject(error)
            );
          } else {
            logout();
          }
        } else {
          return (
            Promise.reject(error["response"]!["data"]) ?? Promise.reject(error)
          );
        }
      }
    );

    const isAuthorizedURL = (url: any) => {
      const authorizedEndpoints = ["/auth/login/"];

      try {
        const parsedURL = new URL(url);

        const isEndpointAllowed = authorizedEndpoints.some((endpoint) =>
          parsedURL.pathname.includes(endpoint)
        );

        return isEndpointAllowed;
      } catch (e) {
        // Invalid URL
        return false;
      }
    };

    const isRoleEndpoints = (url: any) => {
      const authorizedEndpoints = ["/users"];

      try {
        const parsedURL = new URL(url);

        const isEndpointAllowed = authorizedEndpoints.some((endpoint) =>
          parsedURL.pathname.includes(endpoint)
        );

        return isEndpointAllowed;
      } catch (e) {
        // Invalid URL
        return false;
      }
    };

    // Request interceptor to add access token to every request
    const requestInterceptor = api.interceptors.request.use(
      (config) => {
        if (
          accessToken &&
          !isAuthorizedURL(config?.url) &&
          !config.url?.includes("refresh")
        ) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        if (
          isRoleEndpoints(config?.url) &&
          window.location.href.includes("/register") &&
          !config.url?.includes("refresh")
        ) {
          config.headers["Authorization"] = `Bearer ${
            import.meta.env.FRONTEND_KEY
          }`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return () => {
      // Clean up the interceptors when the component unmounts
      api.interceptors.response.eject(interceptor);
      api.interceptors.request.eject(requestInterceptor);
    };
  }, [accessToken]);

  return null;
}

export { ApiInterceptor, api };
