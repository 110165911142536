import { getStoreComponents, getStoreTags } from "@/api/componentApi";
import PaginatorComponent from "@/components/PaginatorComponent";
import ShadTooltip from "@/components/ShadTooltipComponent";
import CollectionCardComponent from "@/components/cardComponent";
import Header from "@/components/header";
import IconComponent from "@/components/iconComponent";
import PageLayout from "@/components/pageLayout";
import { SkeletonCardComponent } from "@/components/skeletonCardComponent";
import { TagsSelector } from "@/components/tagsSelectorComponent";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast";
import { UUIDREGEX } from "@/constants/constants";
import { storeComponent } from "@/types/api";
import { cn } from "@/utils/utils";
import { uniqueId } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

export default function StorePage(): JSX.Element {
  const validApiKey = true,
    hasApiKey = true,
    loadingApiKey = false,
    apiKey = "api";
  const setValidApiKey = (value: boolean) => {};
  const setErrorData = (data: { title: string; list: string[] }) => {
    toast({
      title: data.title,
      description: (
        <ul className="list-disc list-inside">
          {data.list.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      ),
    });
  };
  const StoreApiKeyModal = (children: any, disabled: boolean) => {
    return <></>;
  };
  const setTabId = (id: string) => {};

  const [loading, setLoading] = useState(true);
  const [loadingTags, setLoadingTags] = useState(true);
  const { id } = useParams();
  const [filteredCategories, setFilterCategories] = useState<any[]>([]);
  const [inputText, setInputText] = useState<string>("");
  const [searchData, setSearchData] = useState<storeComponent[]>([]);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageOrder, setPageOrder] = useState("Newest");
  const [tags, setTags] = useState<{ id: string; name: string }[]>([]);
  const [tabActive, setTabActive] = useState("All");
  const [searchNow, setSearchNow] = useState("");
  const [selectFilter, setSelectFilter] = useState("all");

  useEffect(() => {
    handleGetTags();
  }, []);

  useEffect(() => {
    if (!loadingApiKey) {
      if (!hasApiKey) {
        setErrorData({
          title: "API Key Error",
          list: [
            "You don't have an API Key. Please add one to use the Langflow Store.",
          ],
        });
        setLoading(false);
      } else if (!validApiKey) {
        setErrorData({
          title: "API Key Error",
          list: [
            "Your API Key is not valid. Please add a valid API Key to use the Langflow Store.",
          ],
        });
      }
    }
  }, [loadingApiKey, validApiKey, hasApiKey]);

  useEffect(() => {
    handleGetComponents();
  }, [
    tabActive,
    pageOrder,
    pageIndex,
    pageSize,
    filteredCategories,
    selectFilter,
    validApiKey,
    hasApiKey,
    apiKey,
    searchNow,
    loadingApiKey,
    id,
  ]);

  function handleGetTags() {
    setLoadingTags(true);
    getStoreTags()
      .then((res) => {
        setTags(res);
        setLoadingTags(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingTags(false);
      });
  }

  function handleGetComponents() {
    let inputSearch = inputText;
    let idSearch = id;

    let sortName = "-date_created";

    switch (pageOrder) {
      case "Popular":
        sortName = "-count(downloads)";
        break;
      case "Alphabetical":
        sortName = "name";
        break;
      case "Oldest":
        sortName = "date_created";
        break;
      default:
        "-date_created";
        break;
    }

    if (UUIDREGEX.test(inputText)) {
      idSearch = inputSearch;
      inputSearch = "";
    }

    if (!hasApiKey || loadingApiKey) return;
    setLoading(true);
    getStoreComponents({
      component_id: idSearch,
      page: pageIndex,
      limit: pageSize,
      is_component:
        tabActive === "All" ? null : tabActive === "Flows" ? false : true,
      sort: sortName,
      tags: filteredCategories,
      liked: selectFilter === "likedbyme" && validApiKey ? true : null,
      isPrivate: null,
      search: inputSearch === "" ? null : inputSearch,
      filterByUser: selectFilter === "createdbyme" && validApiKey ? true : null,
    })
      .then((res) => {
        if (!res?.authorized && validApiKey === true) {
          setValidApiKey(false);
          setSelectFilter("all");
        } else {
          if (res?.authorized) {
            setValidApiKey(true);
          }
          setLoading(false);
          setSearchData(res?.results ?? []);
          setTotalRowsCount(
            filteredCategories?.length === 0
              ? Number(res?.count ?? 0)
              : res?.results?.length ?? 0
          );
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403 || err?.response?.status === 401) {
          setValidApiKey(false);
        } else {
          setSearchData([]);
          setTotalRowsCount(0);
          setLoading(false);
          setErrorData({
            title: "Error getting components.",
            list: [err?.response?.data?.detail ?? "An error has occurred."],
          });
        }
      });
  }

  // Set a null id
  useEffect(() => {
    setTabId("");
  }, []);

  function resetPagination() {
    setPageIndex(1);
    setPageSize(12);
  }

  return (
    <PageLayout
      title="Langflow Store"
      description="Search flows and components from the community."
      button={
        <>
          {StoreApiKeyModal && (
            <StoreApiKeyModal disabled={loading}>
              <Button
                disabled={loading}
                className={cn(
                  `${!validApiKey ? "animate-pulse border-error" : ""}`,
                  loading ? "cursor-not-allowed" : ""
                )}
                variant="primary"
              >
                <IconComponent name="Key" className="mr-2 w-4" />
                API Key
              </Button>
            </StoreApiKeyModal>
          )}
        </>
      }
    >
      <div className="flex h-full w-full flex-col justify-between">
        <div className="flex w-full flex-col gap-4 p-0">
          <div className="flex items-end gap-4">
            <div className="relative h-12 w-[40%]">
              <Input
                type="text"
                disabled={loading}
                placeholder="Search Flows and Components"
                className="absolute h-12 pl-5 pr-12"
                onChange={(e) => {
                  setInputText(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchNow(uniqueId());
                  }
                }}
                value={inputText}
              />
              <button
                disabled={loading}
                className="absolute bottom-0 right-4 top-0 my-auto h-6 cursor-pointer stroke-1 text-muted-foreground"
                onClick={() => {
                  setSearchNow(uniqueId());
                }}
              >
                <IconComponent
                  name={loading ? "Loader2" : "Search"}
                  className={loading ? " animate-spin cursor-not-allowed" : ""}
                />
              </button>
            </div>
            <div className="ml-4 flex w-full gap-2 border-b border-border">
              <button
                disabled={loading}
                onClick={() => {
                  setTabActive("All");
                }}
                className={
                  (tabActive === "All"
                    ? "border-b-2 border-primary p-3"
                    : " border-b-2 border-transparent p-3 text-muted-foreground hover:text-primary") +
                  (loading ? " cursor-not-allowed " : "")
                }
              >
                All
              </button>
              <button
                disabled={loading}
                onClick={() => {
                  resetPagination();
                  setTabActive("Flows");
                }}
                className={
                  (tabActive === "Flows"
                    ? "border-b-2 border-primary p-3"
                    : " border-b-2 border-transparent p-3 text-muted-foreground hover:text-primary") +
                  (loading ? " cursor-not-allowed " : "")
                }
              >
                Flows
              </button>
              <button
                disabled={loading}
                onClick={() => {
                  resetPagination();
                  setTabActive("Components");
                }}
                className={
                  (tabActive === "Components"
                    ? "border-b-2 border-primary p-3"
                    : " border-b-2 border-transparent p-3 text-muted-foreground hover:text-primary") +
                  (loading ? " cursor-not-allowed " : "")
                }
              >
                Components
              </button>
              <ShadTooltip content="Coming Soon">
                <button className="cursor-not-allowed p-3 text-muted-foreground">
                  Bundles
                </button>
              </ShadTooltip>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <Select
              disabled={loading}
              onValueChange={setSelectFilter}
              value={selectFilter}
            >
              <SelectTrigger className="mr-4 w-[160px] flex-shrink-0">
                <SelectValue placeholder="Filter Values" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="all">All</SelectItem>
                  <SelectItem value="createdbyme">Created By Me</SelectItem>
                  <SelectItem value="likedbyme">Liked By Me</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
            {id === undefined ? (
              <TagsSelector
                tags={tags}
                loadingTags={loadingTags}
                disabled={loading}
                selectedTags={filteredCategories}
                setSelectedTags={setFilterCategories}
              />
            ) : (
              <Badge
                key="id"
                variant="outline"
                size="sq"
                className="gap-2 bg-beta-foreground text-background hover:bg-beta-foreground"
              >
                <Link to={"/store"} className="cursor-pointer">
                  <IconComponent name="X" className="h-4 w-4" />
                </Link>
                {id}
              </Badge>
            )}
          </div>
          <div className="flex items-end justify-between">
            <span className="px-0.5 text-sm text-muted-foreground">
              {(!loading || searchData.length !== 0) && (
                <>
                  {totalRowsCount} {totalRowsCount !== 1 ? "results" : "result"}
                </>
              )}
            </span>

            <Select
              disabled={loading}
              onValueChange={(e) => {
                setPageOrder(e);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Newest" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Newest">Newest</SelectItem>
                <SelectItem value="Popular">Popular</SelectItem>
                <SelectItem value="Alphabetical">Alphabetical</SelectItem>
                <SelectItem value="Oldest">Oldest</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="grid w-full gap-4 md:grid-cols-2 lg:grid-cols-3">
            {!loading || searchData.length !== 0 ? (
              searchData.map((item) => {
                return (
                  <>
                    <CollectionCardComponent
                      key={item.id}
                      data={item}
                      authorized={validApiKey}
                      disabled={loading}
                    />
                  </>
                );
              })
            ) : (
              <>
                <SkeletonCardComponent />
                <SkeletonCardComponent />
                <SkeletonCardComponent />
              </>
            )}
          </div>

          {!loading && searchData?.length === 0 && (
            <div className="mt-6 flex w-full items-center justify-center text-center">
              <div className="flex h-full w-full flex-col">
                <div className="flex w-full flex-col gap-4">
                  <div className="grid w-full gap-4">
                    {selectFilter != "all" ? (
                      <>
                        You haven't{" "}
                        {selectFilter === "createdbyme" ? "created" : "liked"}{" "}
                        anything with the selected filters yet.
                      </>
                    ) : (
                      <>
                        There are no{" "}
                        {tabActive == "Flows" ? "Flows" : "Components"} with the
                        selected filters.
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {!loading && searchData.length > 0 && (
          <div className="relative py-6">
            <PaginatorComponent
              storeComponent={true}
              pageIndex={pageIndex}
              pageSize={pageSize}
              totalRowsCount={totalRowsCount}
              paginate={(pageSize, pageIndex) => {
                setPageIndex(pageIndex);
                setPageSize(pageSize);
              }}
            ></PaginatorComponent>
          </div>
        )}
      </div>
    </PageLayout>
  );
}
